<template>
	<el-drawer
		title="查看信息"
		:wrapperClosable="false"
		:visible.sync="drawer_"
		destroy-on-close
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="50%"
		@open="open"
		@close="
			() => {
				$refs['ruleForm'].resetFields();
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<!-- 抽屉 -->
			<div class="edit" style="width: 100%">
				<div
					v-if="errorInfo.presidentStatus == 5"
					style="
						padding: 15px 30px;
						background-color: #fcebea;
						width: 95%;
						margin: 0 auto;
					"
				>
					<div
						style="
							color: #d93026;
							font-weight: 800;
							font-size: 13px;
							margin-bottom: 10px;
						"
					>
						<i class="el-icon-error"></i> 审核不通过
					</div>
					<div
						style="
							color: #d93026;
							font-size: 12px;
							text-indent: 13px;
							line-height: 16px;
						"
					>
						{{ errorInfo.examineReasonDescription }}
					</div>
				</div>
				<el-form
					v-if="errorInfo.presidentStatus == 5"
					:model="ruleForm"
					status-icon
					ref="ruleForm"
					class="demo-ruleForm"
					style="padding: 0 60px"
					label-position="rigth"
					label-width="120px"
				>
					<el-form-item label="姓名:">
						{{ errorInfo.cooperationPresidentName }}
					</el-form-item>
					<el-form-item label="手机号:">
						{{ errorInfo.telephone }}
					</el-form-item>
					<el-form-item
						:rules="[{ required: true, message: '职务不能为空' }]"
						label="职务:"
						prop="post"
					>
						<el-select
							style="width: 100%"
							v-model="ruleForm.post"
							placeholder="请选择职务"
						>
							<el-option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						:rules="[
							{ required: true, message: '所属机构不能为空' },
						]"
						label="所属机构:"
						prop="registerOrganization"
					>
						<el-input
							placeholder="请输入所属机构"
							v-model="ruleForm.registerOrganization"
						></el-input>
					</el-form-item>
					<el-form-item
						:rules="[
							{
								required: true,
								message: '所属网点/部门不能为空',
							},
						]"
						label="所属网点/部门:"
						prop="registerNetwork"
					>
						<el-input
							placeholder="请输入所属网点/部门"
							v-model="ruleForm.registerNetwork"
						></el-input>
					</el-form-item>
					<el-form-item
						:rules="[
							{ required: true, message: '个人简介不能为空' },
						]"
						label="个人简介:"
						prop="registerProfile"
					>
						<el-input
							type="textarea"
							placeholder="请输入个人简介"
							v-model="ruleForm.registerProfile"
							:autosize="{ minRows: 3, maxRows: 14 }"
						></el-input>
					</el-form-item>
				</el-form>
				<div style="padding-top: 65px" v-else>
					<el-empty
						description="你的信息正在审核中，审核完毕后会显示网点信息，请稍等..."
					></el-empty>
				</div>
				<div class="demo-drawer__footer">
					<el-button
						type="primary"
						v-if="errorInfo.presidentStatus == 5"
						@click="submitForm('ruleForm')"
						>提 交</el-button
					>
					<el-button @click="$emit('close')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {
	itemByType,
	readCooperationPresident,
	resubmitRegisterInfo,
} from '@/api/index.js';
export default {
	name: 'examineDrawer',
	props: {
		reautVisible: {
			type: Boolean,
			required: true,
		},
		paramsId: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: {
				mobile: '',
				alias: '',
				registerOrganization: '',
				post: '',
				registerNetwork: '',
				registerProfile: '',
				cooperationPresidentId: '',
			},
			options: [],
			errorInfo: {},
		};
	},
	methods: {
		open() {
			this.getReadCooperationPresident();
		},
		submitForm(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					this.ruleForm.cooperationPresidentId =
						this.$store.state.userInfo.userVo.cooperationPresidentId;
					this.ruleForm.mobile = this.errorInfo.telephone;
					this.ruleForm.alias =
						this.errorInfo.cooperationPresidentName;
					const res = await resubmitRegisterInfo(this.ruleForm);
					if (res.returncode == 0) {
						this.$message({
							message: '提交成功',
							type: 'success',
						});
						this.$emit('close');
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		async itemByType() {
			const res = await itemByType({
				dictionaryType: 'CooperationPresidentPost',
			});
			if (res.returncode === 0) {
				this.options = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
		async getReadCooperationPresident() {
			const res = await readCooperationPresident({
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
			});
			this.errorInfo = res;
		},
	},
	created() {
		this.itemByType();
	},
	computed: {
		drawer_: {
			get() {
				return this.reautVisible;
			},
			set(v) {
				this.$emit('update:reautVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.mb {
	margin-bottom: 0px;
}
.box-card {
	margin-bottom: 10px;
}
.demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}
.demo-drawer__footer button {
	width: 20% !important;
	margin: 0 10px;
}
.el-date-editor {
	width: 100%;
}

.edit {
	p {
		font-weight: 800;
		margin-bottom: 20px;
	}
	span {
		text-indent: 40px;
		display: block;
		margin-bottom: 10px;
		font-weight: 800;
	}
}
</style>
